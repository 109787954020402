import { gql } from '@apollo/client'

export const GET_RESOURCE_BY_ID_QUERY = gql`
  query ContentfulGetResourceById($preview: Boolean!, $resourceId: String!) {
    resourcePage(preview: $preview, id: $resourceId) {
      resourcePageTitle
      sys {
        id
        publishedAt
      }
      resourcePageContent
      menuCategory
    }
  }
`

import React from 'react'
import { login, isAuthenticated } from '../../utils/auth'
import { Badge, Pane, toaster } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  markdownStyles,
  navStyles,
  tableSectionStyles
} from '../../styles/sharedComponentStyles'
import { useQuery } from '@apollo/client'
import * as queryString from 'query-string'
import LoadingSpinner from '../../components/LoadingSpinner'
import myTheme from '../../styles/theme'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import 'react-accessible-accordion/dist/fancy-example.css'
import ScrollButton from '../../components/ScrollButton'
import { useGlobalStore } from '../../hooks/useGlobalStore'
import { GET_RESOURCE_BY_ID_QUERY } from '../../queries/getResourceById'

const useSharedStyles = createUseStyles({
  ...navStyles,
  ...tableSectionStyles,
  ...markdownStyles,
  content: {
    composes: '$tableContainer',
    backgroundColor: myTheme.colors.white,
    padding: '20px',
    position: 'relative'
  },
  links: {
    display: 'flex',
    justifyContent: 'space-around',
    '& a': {
      textDecoration: 'none',
      color: myTheme.colors.blueNavy
    },
    '& svg': {
      marginBottom: '20px',
      transform: 'scale(2.5)',
      display: 'block',
      color: myTheme.colors.darkTeal
    }
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0'
  },
  resourcesNav: {
    composes: '$secondaryNav',
    margin: '0'
  }
})

const ResourcesPageNav = ({ resource }) => {
  const sharedClasses = useSharedStyles()

  return (
    <>
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.resourcesNav}>
          <h1 data-testid="main-title">{resource?.resourcePageTitle}</h1>
          <Pane display="flex">
            {!resource.sys.publishedAt && (
              <Badge color="orange" marginRight={8}>
                Draft
              </Badge>
            )}
          </Pane>
        </Pane>
      </nav>
    </>
  )
}
const GET_RESOURCES_ERROR_MESSAGE =
  'Sorry, something went wrong getting the  resources list, please try again later.'

const ResourcesPage = ({ params }) => {
  const sharedClasses = useSharedStyles()
  const id = params['resourceId']
  const { isPreview } = useGlobalStore()
  const { data: resourceData, loading } = useQuery(GET_RESOURCE_BY_ID_QUERY, {
    skip: !id,
    variables: {
      resourceId: id.replace(/"/g, ''),
      preview: isPreview
    },
    onError: _ => toaster.danger(GET_RESOURCES_ERROR_MESSAGE)
  })

  const resource = resourceData?.resourcePage

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && !resource && (
        <Pane
          display="flex"
          padding={30}
          justifyContent="center"
          alignItems="center"
        >
          <h2>Sorry, there are not resources with this id.</h2>
        </Pane>
      )}
      {!loading && resource && (
        <>
          <ResourcesPageNav resource={resource} />
          <Pane className={sharedClasses.content}>
            <Pane className={sharedClasses.customAccordion}>
              <ReactMarkdown
                children={resource.resourcePageContent || ''}
                remarkPlugins={[remarkGfm]}
                className={sharedClasses.markdown}
              />
            </Pane>
            <ScrollButton />
          </Pane>
        </>
      )}
    </>
  )
}

export default ResourcesPage
